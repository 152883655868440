import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import logo from "./yabe-logo-transparent.png";
import { BrowserRouter as Router, Routes, Route, Link} from "react-router-dom";
// import Cookies from 'universal-cookie';


export default class Footer extends React.Component {

  handleLogout(){
    //const cookie = new Cookies();
    // while(cookie.get('_yabeXuser_')){
    //   //cookie.remove('_yabeXuser_');
    // }
    // alert("Sie wurden erfolgreich abgemeldet !");  
    // window.location.assign("http://localhost:3000");
  }

  render() {
    //const cookie = new Cookies();
    const links = [];
    // if(cookie.get('_yabeXuser_')){
    //   links.push(
    //     <div>
    //       <Link to = "/" style={{ textDecoration: "none", color: "#ffffff"  }} > Homepage</Link> <br/> 
    //       <Link to = "" style={{ textDecoration: "none", color: "#ffffff"  }} onClick={this.handleLogout}> Logout</Link> <br/>
    //       <Link to = "/upload" style={{ textDecoration: "none", color: "#ffffff"  }} > Upload</Link>
    //     </div>
    //   )
    // }
    // else{
    //   links.push(
    //     <div>
    //       <Link to = "/" style={{ textDecoration: "none", color: "#ffffff"  }} > Homepage</Link> <br/> 
    //       <Link to = "/login" style={{ textDecoration: "none", color: "#ffffff"  }} > Login</Link> <br/>
    //       <Link to = "/register" style={{ textDecoration: "none", color: "#ffffff"  }} > Registrieren</Link> <br/>
    //       <Link to = "/upload" style={{ textDecoration: "none", color: "#ffffff"  }} > Upload</Link>
    //     </div>
    //   )
    // }
    return (
      <div className="footer">
        <Container>
          <Row>
            <Col className='center'>
              <h1>Lovebox</h1>
            </Col>            
          </Row>
        </Container>
    </div>
    );
  }
}
