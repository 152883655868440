import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { FloatingLabel } from "react-bootstrap";
import Cookies from 'universal-cookie';
var sha256 = require('js-sha256');

// ---------------"E-Mail and Password" für Login-Formular----------------------------------------------------------------------------------------------

export default class Login extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      passwort: ""      
    };

    this.handleLogin = this.handleLogin.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }


  async handleLogin() {
    const { passwort } = this.state;

    
    var hash = sha256(passwort);

      await fetch("https://h2993419.stratoserver.net:8080/login", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-type": "application/json",
        },          
        body: JSON.stringify({datakey: hash}),
      }).then(function(res){
        res.text().then(function (text) {
          if(res.status === 200){
              const cookie = new Cookies();
              cookie.set('lbx_t', text, { path: '/'});
              cookie.set('lbx', "7bf161e90a42169b0814e18343597245e2b98f188f9c1785d51fd2d47ac29392", { path: '/'});
              window.location.assign("https://h2993419.stratoserver.net/");
          }
        })
      });
    }
  

  handleInput(event, key) {
    this.setState({ [key]: event.target.value });
  }

  render(){
    
    return(
      <div className = "eap">       

        <FloatingLabel 
          controlId="floatingPassword" 
          label="Passwort">
        <Form.Control 
          type="password" 
          placeholder="Passwort" 
          onChange={(e) => this.handleInput(e, "passwort")}/>
        </FloatingLabel>

        <Button 
          variant="dark"
          className = "buttonlogin" 
          onClick={this.handleLogin}> 
          Login
        </Button>
      </div> 
      );
    }
}