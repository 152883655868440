import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import MessagePanel from './components/MessagePanel';
import Login from './components/Login'

export default function App() {
  return (

      <Router>
        <Routes>

          <Route path="/" element={<CreateMessage/>}/>

          <Route path="/login" element={<MyLogin/>}/>         

        </Routes>
      </Router>

   );
  }



function CreateMessage() {
return(
      <div>
        <Header/>
        <div class = "rand"><MessagePanel/> </div>
        <Footer/>
    </div>
    );
}

function MyLogin() {
  return(
      <div>
        <Header/>
        <Login/>
        <Footer/>
      </div>
      );
  }
