import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import { Form } from 'react-bootstrap';
import { FloatingLabel } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import {Col, Row} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Cookies from "universal-cookie";

Number.prototype.padLeft = function(base,chr){
    var  len = (String(base || 10).length - String(this).length)+1;
    return len > 0? new Array(len).join(chr || '0')+this : this;
}

export default class MessagePanel extends React.Component{
    constructor(props) {
      super(props);
      this.state = {
        date: new Date(),
        first_line: "",
        second_line: "",
        third_line: "",
        fourth_line: "",
        intervall: "0",
        displayTime: "",
        displayDate: "",
        password: ""
      };
  
      this.handleSmiley = this.handleSmiley.bind(this);
      this.handleInput = this.handleInput.bind(this);
      this.handleCreateMessage = this.handleCreateMessage.bind(this);
    }

componentDidMount() {
    const cookie = new Cookies();
    this.setState({ email: cookie.get('_yabeXuser_')});
}

componentWillUnmount() {
    // clearInterval(this.timerID);
}

handleInput(event, key) {
  this.setState({ [key]: event.target.value });
} 

handleSmiley(event, key, letter) {
  this.setState({ [key]: this.state.fourth_line + letter });
} 

handleCreateMessage(e){
  e.preventDefault()
  const cookie = new Cookies();

  var data = JSON.stringify({
    "first_line": this.state.first_line,
    "second_line": this.state.second_line,
    "third_line": this.state.third_line,
    "fourth_line": this.state.fourth_line,
    "user": "1",
    "timestamp_to_display": this.state.displayDate + " " + this.state.displayTime,
    "display_interval": this.state.intervall,
    "password": this.state.password,
    "sessiontoken": cookie.get('lbx_t')
  });

  var config = {
    method: 'post',
    url: 'https://h2993419.stratoserver.net:8080/createMessage',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
  
  axios(config)
  .then(function (response) {
    console.log(JSON.stringify(response.data));
    if(response.status === 200){
      alert("Nachricht wurde erfolgreich erstellt!");
      window.location.assign("https://h2993419.stratoserver.net/");
    }
  })
  .catch(function (error) {
    console.log(error);
    alert("Nachricht erstellen fehlgeschlagen!");
  });

}

render(){
    const currentDate = [this.state.date.getFullYear(),
        (this.state.date.getMonth()+1).padLeft(),
        this.state.date.getDate().padLeft()].join('-');

  const cookie = new Cookies();
    if(cookie.get('lbx') !== "7bf161e90a42169b0814e18343597245e2b98f188f9c1785d51fd2d47ac29392"){
      window.location.assign("https://h2993419.stratoserver.net/login");
    }
        
    return(
      <div className = "eap">       

        <FloatingLabel
          controlId="floatingInput"
          label={`Erste Zeile - ${(this.state.first_line.length)}/20`}
          className="mb-3">
          <Form.Control 
            type="text" 
            placeholder="" 
            onChange={(e) => this.handleInput(e, "first_line")} />
        </FloatingLabel>
        <FloatingLabel
          controlId="floatingInput"
          label={`Zweite Zeile - ${(this.state.second_line.length)}/20`}
          className="mb-3">
          <Form.Control 
            type="text" 
            placeholder="" 
            onChange={(e) => this.handleInput(e, "second_line")} />
        </FloatingLabel>
        <FloatingLabel
          controlId="floatingInput"
          label={`Dritte Zeile - ${(this.state.third_line.length)}/20`}
          className="mb-3">
          <Form.Control 
            type="text" 
            placeholder="" 
            onChange={(e) => this.handleInput(e, "third_line")} />
        </FloatingLabel>
        <FloatingLabel
          controlId="floatingInput"
          label={`Vierte Zeile - ${(this.state.fourth_line.length)}/14`}
          className="mb-3">
          <Form.Control 
            type="text" 
            placeholder="" 
            value={this.state.fourth_line}
            onChange={(e) => this.handleInput(e, "fourth_line")} />
        </FloatingLabel>
        <Button variant="dark" className="buttonLBX" onClick={(e) => this.handleSmiley(e, "fourth_line", 'h')}>Herz Umriss</Button>{' '}
        <Button variant="dark" className="buttonLBX" onClick={(e) => this.handleSmiley(e, "fourth_line", 'm')}>Brief</Button>{' '}
        <Button variant="dark" className="buttonLBX" onClick={(e) => this.handleSmiley(e, "fourth_line", 'f')}>Herz gefüllt</Button>{' '}
        <Button variant="dark" className="buttonLBX" onClick={(e) => this.handleSmiley(e, "fourth_line", 's')}>:-)</Button>{' '}
        <Button variant="dark" className="buttonLBX" onClick={(e) => this.handleSmiley(e, "fourth_line", 'y')}>:-(</Button>{' '}
        <Button variant="dark" className="buttonLBX" onClick={(e) => this.handleSmiley(e, "fourth_line", 'z')}>:-P</Button>{' '}
        <Button variant="dark" className="buttonLBX" onClick={(e) => this.handleSmiley(e, "fourth_line", 't')}>Tanne</Button>{' '}
        <Button variant="dark" className="buttonLBX" onClick={(e) => this.handleSmiley(e, "fourth_line", 'x')}>Zipfelmütze</Button>{' '}

        <FloatingLabel controlId="floatingSelect" label="Intervall">
                <Form.Select  aria-label="Default select example" name="intervall"  onChange={(e) => this.handleInput(e, "intervall")}>
                            <option>0</option>
                            <option>d</option>
                            <option>w</option>
                            <option>y</option>                            
                        </Form.Select> 
                    </FloatingLabel>   

        <FloatingLabel
          controlId="floatingInput"
          label="Password"
          className="mb-3">
          <Form.Control 
          type="password" 
          placeholder="" 
          value={this.state.password}
          onChange={(e) => this.handleInput(e, "password")} />
        </FloatingLabel>                
        
        <Form.Group as={Row} className="mb-3" controlId="formHorizontalSartTime"></Form.Group>
        <InputGroup className="mb-3">
        <Col className = "startdatum">

            <InputGroup.Text id="basic-addon1">Anzeigedatum</InputGroup.Text>
            <div className="input">
                <input type="date" id="startdatum" name="stardatum" min={currentDate} /*value={currentDate} */ onChange={(e) => this.handleInput(e, "displayDate")}/> 
            </div>            
        </Col>
        <Col className = "startzeit">
            <InputGroup.Text id="basic-addon1">Anzeigezeit</InputGroup.Text>
            <div className="input">
                <input type="time" id="startzeit" name="startzeit" min="00:00" /*value={currentTime} */  onChange={(e) => this.handleInput(e, "displayTime")}/>
            </div>
        </Col>
        </InputGroup>   

        <Button variant="outline-dark"
            onClick={this.handleCreateMessage}> 
            Absenden
        </Button>{' '}
      </div> 
      );
    }
}