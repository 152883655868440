import '../App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Cookies from "universal-cookie";
import { Button } from 'react-bootstrap';
// import Cookies from 'universal-cookie';


export default class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }
  
  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }
  
  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }
  
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  handleLogout(){
    const cookie = new Cookies();
    while(cookie.get('lbx')){
      cookie.remove('lbx');
    }
    while(cookie.get('lbx_t')){
      cookie.remove('lbx_t');
    }
    alert("Sie wurden erfolgreich abgemeldet !");
    window.location.assign("https://h2993419.stratoserver.net/login");       
  }  

  render() {
    const { width } = this.state;
    const isMobile = width <= 580;  

    // -----------Mobile-Version------------------------------------------------------------------------------------------------------------------------
    if (isMobile) {
      return (
        <div className="header">
        <Container>
          <Row>
            <Col sm className='center'>
              <h1>Lovebox</h1>
            </Col>
            <Col sm className='center'>
              <Button onClick={this.handleLogout}
                variant = "light">
                Logout
              </Button>
            </Col>
            </Row>
        </Container>
      </div>
      );
    } else {
      return (
        <div className="header">
        <Container>
          <Row>
            <Col sm className = "center">
              <h1>Lovebox</h1>
            </Col>
            <Col>
              <Button onClick={this.handleLogout}
                variant = "light">
                Logout
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      );
    }
  }
}
